
export const getTemplate = async (params) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/get-template?id=${params.id}`
    const abortController = new AbortController();
    const { signal } = abortController;
    try {
        const response = await fetch(
            url,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "token",
                },
                signal
            }
        );

        if (response.status !== 200)
            throw new Error(
                `Fetch did not return OK status : ${JSON.stringify(response)}`
            );
        if (signal.aborted) {
            abortController.abort();
        }
        const data = await response.json();

        abortController.abort();
        if (data.errorType || data.errorMessage) {
            throw new Error(`Failed to fetch. Reason: ${data.errorMessage}`);
        }

        return data;
    } catch (err) {
        console.log(err)
        throw new Error(err);
    }
}