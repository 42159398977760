import React from "react";
import { Box } from "@mui/material";
import Rating from "@mui/material/Rating";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";

function RatingItem({ question, updateQuestion }) {
  
  return (
    <>
      <FormLabel
        sx={{
          color: "rgba(0, 0, 0, 0.6) !important",
        }}
      >
        {question.questionLabel}
      </FormLabel>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "10px",
          marginBottom: "30px",
        }}
      >
        <Rating
          size="large"
          name="simple-controlled"
          onChange={(event) => {
            question.value = event.target.value;
            updateQuestion(question);
          }}
        />
      </Box>

    </>
  );
}

export default RatingItem;
