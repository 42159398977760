import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import Form from "./components/pages/formPage";
import ThankYouPage from "./components/pages/thankYouPage";

function Routing () {
    return (
        <Routes>
            <Route path="/" element={<ThankYouPage/>} />
            <Route path="/survey" element={<Form/>} />
            <Route path="*" element={<Navigate to="/"/>} />
        </Routes>   
    )
} 

export default Routing;