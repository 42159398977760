exports.mainSurvey = {
    "questions": [
        {
            "options": [
            ],
            "questionId": "baef67ae-aca0-41c8-919e-8f3564aa77cb",
            "questionLabel": "How would you rate our service? ",
            "questionType": "Rating",
            "value": ""
        },
        {
            "options": [
            ],
            "questionId": "4145b190-321f-498d-89e2-42df72dd0378",
            "questionLabel": "What in your opinion was good?",
            "questionType": "Text",
            "value": ""
        },
        {
            "options": [
            ],
            "questionId": "61fec166-2cf1-4bb7-881e-de7ffebd7344",
            "questionLabel": "What in your opinion was bad?",
            "questionType": "Text",
            "value": ""
        },
        {
            "options": [
            ],
            "questionId": "af77e96d-25ac-4371-b8aa-5c3e7cda5e4b",
            "questionLabel": "What in your opinion could be better?",
            "questionType": "Text",
            "value": ""
        }
    ]
}