import { Box } from "@mui/material";
function MainPage() {
  return (
    <Box
      sx={{
        color: "rgba(0, 0, 0, 0.6)",
        borderRadius: "10px",
        backgroundColor: "rgb(255, 255, 255)",
        padding: ["45px", "45px"],
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img className="logo" src="/ncelogo.png" width={150} height={150}></img>
      <Box
        sx={{
          fontSize: "1.2rem",
          fontFamily: ["Arial"],
          fontWeight: 350,
          letterSpacing: "0.02em",
        }}
      >
        CUSTOMER SATISFACTION FORM
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "1.2rem",
          fontWeight: 350,
          marginTop: "40px",
          letterSpacing: "0.02em",
        }}
      >
        <div>THANK YOU!</div>
        <div>YOUR ANSWER WAS SUBMITED</div>
      </Box>
    </Box>
  );
}

export default MainPage;
