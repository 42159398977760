import React from "react";
import {
  Box,
  Button,
  FormControl,
} from "@mui/material";
import TextFieldItem from "../../molecules/textFieldItem";
import RatingItem from "../../molecules/ratingItem";
import RadioItem from "../../molecules/radioItem";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation, useNavigate } from "react-router-dom";
import { getTemplate } from "../../../api/getTemplate";
import { submitForm } from "../../../api/submitForm";
import { checkToken } from "../../../api/checkToken";
import DropdownItem from "../../molecules/dropdownItem";
import { mainSurvey } from "../../../constants";

function MainPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [templateId] = React.useState(params.get("id"));
  const token = params.get("token");
  const [questions, setQuestions] = React.useState([]);
  const [isAuthorized, setIsAuthorized] = React.useState(false);
  const updateQuestion = (question) => {
    const { questionId } = question;
    questions.forEach((element, index) => {
      if (element.questionId == questionId) {
        questions[index] = question;
        setQuestions([...questions]);
        return;
      }
    });
  }

  React.useEffect(() => {
    const handleGetTemplate = async () => {
      try {
        let template;
        if (sessionStorage.getItem("template_id")) {
          template = await getTemplate({
            id: sessionStorage.getItem("template_id")
          });
        } else {
          template = mainSurvey;
        }

        template.questions.forEach((question, index) => {
          template.questions[index] = {
            ...question,
            setValue: function (value) {
              this.value = value;
            },
          }
        });
        setQuestions(template.questions);
      } catch (err) {
        console.log(err);
      }
    };
    handleGetTemplate();
  }, []);

  React.useEffect(() => {
    const handleGetTemplate = async () => {
      try {
        let template;
        if (sessionStorage.getItem("template_id")) {
          template = await getTemplate({
            id: sessionStorage.getItem("template_id")
          });
        } else {
          template = mainSurvey;
        }

        template.questions.forEach((question, index) => {
          template.questions[index] = {
            ...question,
            setValue: function (value) {
              this.value = value;
            },
          }
        });
        setQuestions(template.questions);
      } catch (err) {
        console.log(err);
      }
    };

    const validate = async () => {
      try {
        const resp = await checkToken(sessionStorage.getItem("token"), false);
        const { isAuthorized } = resp;
        if (!isAuthorized) throw Error("Unauthorized!");
        setIsAuthorized(true);
      } catch (err) {
        navigate("/");
      }
    };
    if (token) {
      sessionStorage.setItem("token", token);
      if(templateId != null){
        sessionStorage.setItem("template_id", templateId);
      }
      navigate("/survey");
    } else if (sessionStorage.getItem("token")) {
      handleGetTemplate();
      validate();
    } else {
      navigate("/");
    }
  }, [token]);

  if (isAuthorized) {
    return (
      <Box
        sx={{
          color: "rgba(0, 0, 0, 0.6)",
          borderRadius: "10px",
          backgroundColor: "rgb(255, 255, 255)",
          padding: ["45px", "45px"],
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src="/ncelogo.png" width={150} height={150}></img>
        <Box
          sx={{
            fontSize: "1.2rem",
            fontFamily: ["Arial"],
            fontWeight: 350,
            letterSpacing: "0.02em",
            marginBottom: "40px"
          }}
        >
          CUSTOMER SATISFACTION FORM
        </Box>
        <Box>
          <form
            onSubmit={(e) => {
              const token = sessionStorage.getItem("token");
              e.preventDefault();
              submitForm({
                token,
                questions,
                templateId
              });
              navigate("/");
            }}
          >
            <FormControl>
              {questions.map((question, index) => {
                switch (question.questionType) {
                  case "Text":
                    return (
                      <TextFieldItem question={question} updateQuestion={updateQuestion} key={`${index}-text-field`} />
                    );
                  case "Rating":
                    return (
                      <RatingItem question={question} updateQuestion={updateQuestion} key={`${index}-radio`} />
                    );
                  case "Radio":
                    return (
                      <RadioItem question={question} updateQuestion={updateQuestion} key={`${index}-radio`} />
                    );
                  case "Dropdown":
                    return (
                      <DropdownItem question={question} updateQuestion={updateQuestion} key={`${index}-dropdown`} />
                    );
                }
              })}
              <Button
                variant="contained"
                type="submit"
                sx={{ marginTop: "10px" }}
              >
                Submit
              </Button>
            </FormControl>
          </form>
        </Box>
      </Box>
    );
  } else {
    return <CircularProgress />;
  }
}

export default MainPage;
